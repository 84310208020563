<template lang="pug">
    .senders.view
        section.compact(v-if="sender.id && !sender.unsubscribed")
            .section-content-wrapper
                h2 SMS-рассылки {{ sender.name }}
                el-button(@click="unsubscribeFromSender" type="primary") Отписаться от этих рассылок
        section.compact.senders-list(v-if="!subscriber.deny_all_subscriptions")
            .section-content-wrapper
                h4(v-if="sender.id && !sender.unsubscribed") Отпишитесь от рассылок других отправителей
                .title(v-else)
                    b Популярные отправители рассылок
                SendersFilterForm(:senders_filter_form="senders_filter_form" @created="mergeSubscriberSubscriptions" @updated="filterSenders" @reset="resetSendersFilter")
                template(v-if="senders.total")
                    .tip
                        div Клик
                    .sender(v-for="(sender, index) in senders.data" :key="sender.id")
                        .name
                            b {{ sender.name }}
                        .contol(@click.stop="saveSubscriberSubscription(sender)" :class="!index ? 'animate__animated animate__tada' : ''")
                            el-switch(v-model="sender.unsubscribed" :disabled="!subscriber.hasCompany() && !subscriber.terms_accepted_at" :inactive-text="sender.unsubscribed === false ? 'Подписан' : sender.unsubscribed === true ? 'Отписан' : 'Отпишитесь'" :class="sender.unsubscribed ? 'active' : sender.unsubscribed === false ? 'inactive' : ''")
                    .pagination.senders-list-pagination(v-if="senders.to < senders.total")
                        el-button(@click="loadMoreSenders" type="primary") Показать ещё
                .subscription-edit(v-else)
                    h3 Укажите отправителя и отпишитесь:
                    SubscriptionEditForm(:sender_name.sync="senders_filter_form.name" @saved="subcriptionSaved")
        section.compact.subscription-create(v-if="senders.total")
            .section-content-wrapper <strong><a @click="$router.push({name: 'subscription.create'})">Отпишитесь от произвольного отправителя</a></strong>, если не нашли в списке.
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';

import {
    RESET_STORE,
    GET_SENDER,
    GET_SENDERS_SUGGESTIONS,
    GET_SUBSCRIBER_SUBSCRIPTIONS,
    SAVE_SUBSCRIBER,
    SAVE_SUBSCRIBER_SUBSCRIPTION,
    SET_SUBSCRIBER,
} from '@/store/types';

import SubscriptionEditForm from "@/components/Subscription/SubscriptionEditForm";
import UnsubscribeFromAll from "@/components/Subscription/UnsubscribeFromAll";
import SendersFilterForm from "@/components/Senders/SendersFilterForm";

const senders_default_filter_form = () => {
    return {
        sorting: {
            property: 'frequency',
            direction: 'desc',
        },
        channel: {alias: 'sms'},
        tags: [],
        name: '',
    }
}
const default_senders = () => {
    return {
        empty_message: 'Не найдено ни одного отправителя',
        current_page: 1,
        total: 0,
    }
}

export default {
    metaInfo() {
        return {
            title: this.title
        };
    },
    components: {
        SubscriptionEditForm,
        UnsubscribeFromAll,
        SendersFilterForm,
    },
    props: [
        'sender_id',
    ],
    data() {
        return {
            subscription_form_visible: false,
            senders_filter_visible: true,
            terms_dialog_visible: false,
            senders_filter_form: senders_default_filter_form(),
            channels: [{
                alias: 'sms',
                name: 'SMS',
            }],
            sorting_directions: [
                'desc',
                'asc',
            ],
            subscriptions: [],
            senders: default_senders(),
            sender: {
                unsubscribed: undefined
            }
        }
    },
    computed: {
        ...mapGetters([
            'auth_user',
            'subscriber',
        ]),
        title() {
            return 'Популярные отправители';
        },
    },
    methods: {
        ...mapActions([
            GET_SENDER,
            GET_SENDERS_SUGGESTIONS,
            GET_SUBSCRIBER_SUBSCRIPTIONS,
            SAVE_SUBSCRIBER,
            SAVE_SUBSCRIBER_SUBSCRIPTION,
        ]),
        ...mapMutations([
            RESET_STORE,
            SET_SUBSCRIBER,
        ]),
        resetSendersFilter() {
            this.senders_filter_form = senders_default_filter_form();
        },
        getSender() {
            this.GET_SENDER(this.sender_id).then((sender) => {
                this.sender = sender;

                let subscription = this._.filter(this.subscriptions, (subscription) => {
                    return subscription.sender_name === this.sender.name;
                })[0];

                if (subscription) {
                    Object.assign(this.sender, {unsubscribed: subscription.unsubscribed});
                }
            })
        },
        unsubscribeFromSender() {
            this.sender.unsubscribed = true
            this.saveSubscriberSubscription(this.sender)
                .then(() => {
                    this.filterSenders();
                }).then(() => {
                this.getSubscriberSubscriptions();
            })
        },
        onSubscriptionSaved() {
            this.toggleSubscriptionForm();
            this.$router.push({name: 'subscriptions'});
        },
        toggleSubscriptionForm() {
            this.subscription_form_visible = !this.subscription_form_visible;
        },
        getSubscriberSubscriptions() {
            return this.GET_SUBSCRIBER_SUBSCRIPTIONS({subscriber: this.subscriber}).then((subscriptions) => {
                Object.assign(this.subscriptions, subscriptions)
            }).catch((error) => {
                if (error.response.data.errors) {
                    this.$root.pushAppMessages(error.response.data.errors.map((message) => {
                        return this.$t(message)
                    }))
                }

                return Promise.reject(error);
            })
        },
        resetSenders() {
            this.senders = default_senders();
        },
        mergeSubscriberSubscriptions() {
            this.getSubscriberSubscriptions().then(() => {
                if (this.sender_id) this.getSender();
            }).then(() => {
                this.filterSenders();
            })
        },
        filterSenders() {
            this.getSenders(true)
        },
        sortSenders(property) {
            this.senders_filter_form.sorting.property = property
            this.sorting_directions.reverse()
            this.senders_filter_form.sorting.direction = this.sorting_directions[0]
        },
        loadMoreSenders() {
            this.senders.current_page++

            return this.getSenders()
        },
        getSenders(with_reset) {
            if (with_reset) this.resetSenders()

            return this.GET_SENDERS_SUGGESTIONS({...this.senders_filter_form, ...{page: this.senders.current_page}}).then((senders) => {
                let list = (this.senders.data) ? this.senders.data : []

                senders.data = this.mergeSenderStatus(senders.data)

                this.senders = senders

                Object.assign(this.senders.data, list.concat(this.senders.data))
            }).catch((error) => {
                if (error.response.data.errors) {
                    this.$root.pushAppMessages(error.response.data.errors.map((message) => {
                        return this.$t(message)
                    }))
                }

                return Promise.reject(error)
            })
        },
        mergeSenderStatus(senders) {
            return this._.map(senders, (sender) => {
                let subscription = this._.filter(this.subscriptions, (subscription) => {
                    return subscription.sender_name === sender.name
                })[0]

                if (subscription !== undefined) Object.assign(sender, {unsubscribed: subscription.unsubscribed})

                return sender
            })
        },
        saveSubscriberSubscription(sender) {
            this.SET_SUBSCRIBER(Object.assign(this.subscriber, {check_terms_accepted: true}));

            if (this.subscriber.hasCompany() || (this.subscriber.terms_accepted_at !== null)) {
                const subscription = {
                    channel_alias: sender.channel_alias,
                    sender_name: sender.name,
                    unsubscribed: sender.unsubscribed,
                }
                return this.SAVE_SUBSCRIBER_SUBSCRIPTION(subscription)
                    .then((subscription) => {
                        if (subscription.unsubscribed) {
                            if (window.gtag !== undefined) {
                                gtag('event', 'tap_unsubscribe', {
                                    'event_category': 'onboarding'
                                })
                                console.log('GA goal reached: tap_unsubscribe')
                            }

                            if (window.ym !== undefined) {
                                window.ym(74625946, 'reachGoal', 'tap_unsubscribe')
                                console.log('YM goal reached: tap_unsubscribe')
                            }
                        }

                        if (subscription.sender_name === this.sender.name) {
                            Object.assign(this.sender, {unsubscribed: subscription.unsubscribed})

                            console.log(this.sender)
                        }

                    }).catch((error) => {
                        if (error.response.data.errors) {
                            this.$root.pushAppMessages(error.response.data.errors.map((message) => {
                                return this.$t(message)
                            }))
                        }
                    });
            }
        },
        subcriptionSaved() {
            if (window.gtag !== undefined) {
                gtag('event', 'tap_unsubscribe', {
                    'event_category': 'onboarding'
                })
                console.log('GA goal reached: tap_unsubscribe')
            }

            if (window.ym !== undefined) {
                window.ym(74625946, 'reachGoal', 'tap_unsubscribe')
                console.log('YM goal reached: tap_unsubscribe')
            }

            $router.push({name: 'subscriptions'})
        },
    },
}
</script>

<style lang="scss" scoped>
.senders {
    background: $color--background;
    color: $color--black;

    &-list {
        .title {
            //@include font--small;

            padding-bottom: $padding14;
        }

        .tip {
            @include clearfix;

            position: relative;
            //padding-bottom: 30px;

            > * {
                position: absolute;
                z-index: 100;
                top: -40px;
                right: 25px;
                padding: 22px 80px 0 0;
                background: url("/assets/arrow1.png") 100% 0 no-repeat;
            }
        }
    }

    .subscription {
        &-create {
            background: $color--secondary;
            color: $color--black;
        }

        &-edit {
            &-form {
                width: 50%;
            }
        }

        @media (max-width: $max-width--tablets) {
            &-edit {
                &-form {
                    width: 100%;
                }
            }
        }
    }
}

.sender {
    @include border(1px dotted $color--secondary-dark, $position: bottom);

    display: flex;
    justify-content: space-between;
    padding: $padding18 0;
}
</style>
