import { render, staticRenderFns } from "./SendersSuggestions.vue?vue&type=template&id=5ca0ff80&scoped=true&lang=pug&"
import script from "./SendersSuggestions.vue?vue&type=script&lang=js&"
export * from "./SendersSuggestions.vue?vue&type=script&lang=js&"
import style0 from "./SendersSuggestions.vue?vue&type=style&index=0&id=5ca0ff80&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ca0ff80",
  null
  
)

export default component.exports